<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card class="mx-auto">

        <v-row gutters>
          <v-col cols="1" style="padding-right: 0px; margin-top: 12px; width: 15px !important;"
            v-show="pastaSelecionada !== null">
            <v-icon style="cursor: pointer; padding: 0.5rem;" v-on:click="handleOpenFolder(pastaPaiId)">
              {{ icons.mdiArrowLeftCircle }}
            </v-icon>
          </v-col>
          <v-col
            v-show="this.pastas.length === 0 && (!this.pastaSelecionada || !this.pastaSelecionada.arquivos || this.pastaSelecionada.arquivos.length === 0)">
            <v-row>
              <h2 class="font-weight-light" style="padding-right: 0px; margin-top: 28px;">
                Nenhum documento disponível
              </h2>
            </v-row>
          </v-col>
          <v-col cols="11" style="padding-left: 0px;">
            <v-list style="padding: 0.5rem;" v-show="this.pastas.length > 0">
              <v-list-item v-for="(pasta, index) in pastas" :key="index" v-on:click="obterConteudoPasta(pasta.id)" link>
                <v-icon style="cursor: pointer; margin-right: 8px;">
                  {{ icons.mdiFolderOutline }}
                </v-icon>
                <v-list-item-title style="cursor: pointer;">{{ pasta.nome }}</v-list-item-title>
              </v-list-item>
            </v-list>

            <v-list style="padding: 0.5rem;"
              v-if="this.pastaSelecionada !== undefined && this.pastaSelecionada !== null && this.pastaSelecionada.arquivos.length > 0">
              <v-list-item v-for="(arquivo, index) in this.pastaSelecionada.arquivos" :key="index">
                <v-icon style="cursor: pointer; margin-right: 8px;">
                  {{ icons.mdiFilePdfBox }}
                </v-icon>
                <v-list-item-title style="cursor: pointer;" link>
                  <a :href="arquivo.url" target="_blank">{{ arquivo.nome }}</a>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiArrowLeftCircle, mdiFilePdfBox, mdiFolderOutline } from '@mdi/js';
import UsuarioStore from './../../store/Usuario'

export default {
  props: {
    object: '',
    obraId: 0
  },
  data() {
    return {
      pastas: [],
      pastaSelecionada: null,
      pastaPaiId: null,
    };
  },
  mounted() {
    if (typeof this.object !== 'undefined' && this.object === 'obra') {
      this.obterPastasObra(this.obraId)
    } else {
      this.object = 'user'
      this.obterPastasUsuario()
    }
  },
  methods: {
    handleOpenFolder(pastaId) {
      if (pastaId !== null) {
        this.obterConteudoPasta(pastaId)
      } else {
        if (this.object === 'user' && this.obraId === undefined) {
          this.obterPastasUsuario()
        } else {
          this.obterPastasObra(this.obraId)
        }
      }
    },
    obterPastasUsuario() {
      this.$loading(true);
      this.pastaSelecionada = null;
      this.pastaPaiId = null;

      UsuarioStore.obterPastasUsuario().then(result => {
        // Supondo que result.data seja um array de pastas
        this.pastas = result.data;
      }).catch(error => {
        console.error('Erro ao obter pastas do usuário', error);
      }).finally(() => {
        this.$loading(false)
      })
    },
    obterPastasObra(obraId) {
      this.$loading(true);
      this.pastaSelecionada = null;
      this.pastaPaiId = null;

      UsuarioStore.obterPastasObra(obraId).then(result => {
        // Supondo que result.data seja um array de pastas
        this.pastas = result.data;
        console.log('pastas', pastas)
      }).catch(error => {
        console.error('Erro ao obter pastas da obra', error);
      }).finally(() => {
        this.$loading(false)
      })
    },
    obterConteudoPasta(pastaId) {
      this.$loading(true)
      this.pastas = []
      this.pastaSelecionada = null;
      UsuarioStore.obterConteudoPasta(pastaId).then(result => {
        // Supondo que result.data seja um array de pastas
        this.pastas = [...result.data.subpastas];
        this.pastaSelecionada = result.data
        this.pastaPaiId = result.data.pastaPaiId
      }).catch(error => {
        console.error('Erro ao obter conteudo da pasta', error);
      }).finally(() => {
        this.$loading(false)
      });
    }
  },
  setup() {
    return {
      // icons
      icons: {
        mdiFolderOutline,
        mdiArrowLeftCircle,
        mdiFilePdfBox
      },
    }
  },
}
</script>
