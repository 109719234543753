<template>
  <v-row>
    <v-col cols="12" md="12">
      <table-documentos :object="this.object" :obraId="this.obraId" :userId="this.userId"></table-documentos>
    </v-col>
  </v-row>
</template>

<script>

import TableDocumentos from './TableDocumentos.vue'

export default {
  props: {
    object: '',
    obraId: 0,
    userId: 0
  },

  components: {
    TableDocumentos,
  },
  mounted: function () {
  },
}
</script>